<template>
  <app-event event-id="kontur" :mapping="mapping">
    <template v-slot:drawer-bottom v-if="lotteryNumber">
      <v-divider dark />
      <v-sheet elevation="2" class="ma-4 pa-4" color="primary darken-2">
        <div class="d-flex align-center justify-center mb-4">
          Ваш лотерейный билет
        </div>
        <div class="d-flex align-center justify-center">
          <span class="text-h2" style="text-shadow: 1px 1px 2px black">{{
            lotteryNumber
          }}</span>
        </div>
      </v-sheet>
    </template>
    <template v-slot:nav-right v-if="lotteryNumber">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary darken-2"
            class="mr-4"
            elevation="2"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left dark>mdi-ticket</v-icon>
            <span class="text-h5">{{ lotteryNumber }}</span>
          </v-btn>
        </template>
        <span>Ваш лотерейный билет</span>
      </v-tooltip>
    </template>
    <template v-slot:logo>
      <v-img src="https://cdn.your-quest.com/common/logo.png" class="ma-4" />
    </template>
  </app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import Quest from "./Quest";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "AppKonturPageEvent",
  components: {
    AppEvent,
  },
  computed: {
    ...mapGetters({
      user: "event/user",
    }),
    lotteryNumber() {
      return _.first(_.get(this.user, "profile.numbers"));
    },
  },
  data() {
    return {
      mapping: {
        quest: Quest,
      },
    };
  },
};
</script>